<template>
  <div>
    <MiniBanner />
    <div class="imgBox">
      <div class="imgItem" v-for="(img, index) in imgs" :key="index">
        <img class="logoImg" :src="img" alt="" @click="imgClick(index)" />
      </div>
    </div>
  </div>
</template>

<script>
import MiniBanner from "@/components/miniBanner/index";
import { mapActions } from "vuex";

export default {
  name: "MiniCloudHall",
  data() {
    return {
      imgs: [
        require("@/assets/mobile/logo_mobile_GE.jpg"),
        require("@/assets/mobile/logo_mobile_PHILIPS.jpg"),
        require("@/assets/mobile/logo_mobile_SIEMENS.jpg"),
        require("@/assets/mobile/logo_mobile_CANON.jpg"),
        require("@/assets/mobile/logo_mobile_GE_H.jpg"),
        require("@/assets/mobile/logo_mobile_mindray.jpg"),
      ],
      canonVRSrc: 'https://idoctor-live.oss-cn-beijing.aliyuncs.com/SZH/%E4%BD%B3%E8%83%BD%E5%8C%BB%E7%96%973D%E5%B1%95%E5%8E%85%E8%A7%86%E9%A2%91.mp4',
      siemensVRSrc: 'https://idoctor-live.oss-cn-beijing.aliyuncs.com/SZH/%E8%A5%BF%E9%97%A8%E5%AD%90%20MAGNETOM%20Altea%E8%A7%86%E9%A2%91.mp4'
    };
  },
  methods: {
    imgClick(index){
      if(index === 0) {
        window.location.assign('http://ge-2020.h5feel.cn/')
      }else if (index === 3) {
        window.location.assign(this.canonVRSrc)
      }else if (index === 2) {
        window.location.assign(this.siemensVRSrc)
      }
    },
    ...mapActions({
      setTitle: "setTitleFunc",
    }),
  },
  created() {
    this.setTitle("线上展台");
  },
  components: {
    MiniBanner,
  },
};
</script>

<style scoped>
.imgBox {
  width: 100%;
  background-color: #e5e5e5;
  padding-bottom: 15px;
}
.imgItem {
  box-sizing: border-box;
  padding: 15px 15px 0;
}
.logoImg {
  width: 100%;
  display: block;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.logoImg:focus {
  /* opacity: 0.8; */
}
.logoImg:active {
  opacity: 0.9;
}
</style>